<template>
	<div>
		<myheader></myheader>
		<div class="index">
			<div class="supplement">
				<div class="user">
					<img src="" alt="">
					<div>
						<p>Hi，lsiihans</p>
						<p>请绑定您的手机号</p>
					</div>
				</div>
				<div class="inp">
					<input type="text" placeholder="请输入用户名称">
				</div>
				<div class="inp">
					<input type="password" placeholder="请输入密码" class="pass">
					<input type="password" placeholder="请再次输入密码" class="pass">
				</div>
				<div class="sex">
					<div class="flex-around" @click="form.sex=0">
						<img src="../assets/public/check1.png" v-if="form.sex==0">
						<img src="../assets/public/check2.png" v-if="form.sex==1">
						<p>男</p>
					</div>

					<div class="flex-around" @click="form.sex=1">
						<img src="../assets/public/check1.png" v-if="form.sex==1">
						<img src="../assets/public/check2.png" v-if="form.sex==0">
						<p>女</p>
					</div>
				</div>
				<div class="inp">
					<input type="text" placeholder="请输入手机号" maxlength="11">
					<span class="toyzm">发送验证码</span>
				</div>

				<div class="inp">
					<input type="text" placeholder="请输入验证码">

				</div>

				<div class="but" @click="">
					提交
				</div>
			</div>
		</div>

		<myfooter></myfooter>
		<myright></myright>
	</div>

</template>

<script>
import myheader from '../components/header.vue'
import myfooter from '../components/footer.vue'
import myright from '../components/right.vue'

export default {

  components: {
    myheader, myfooter, myright
  },
  data () {
    return {
      keyword: '',
      form: {
        sex: 0
      }
    }
  },
  created () {
    const option = this.$route.query.keyword
    this.keyword = option
    console.log(option)
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped="scoped">
.index{
		min-width: 1200px;
		width: 100%;
		// margin: 0 auto;
		padding: 20px 0;
		background-color: #f9f9f9;

	}
.supplement{
	width: 440px;
	// min-height:360px;
	margin: 0 auto;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px 60px;
	.user{
		display: flex;
		align-items: center;

		img{
			width: 60px;
			height: 60px;
			border-radius: 50%;
			margin-right: 15px;
		}
		div{
			p:first-child{
				line-height: 28px;
				font-weight: 500;
				color: #333;
			}
			p:last-child{
				font-size: 13px;
				line-height: 25px;
				color: #666;
			}
		}

	}
	.inp{
		margin-top: 15px;
		display: flex;
		justify-content: space-between;
		position: relative;

		input{
			width: 321px;
			height: 40px;
			background: #f6f7f9;
			border-radius: 4px;
			text-indent: 15px;
		}
		.yzm{
			width: 170px;
		}
		.toyzm{
			color: #FF7C00;
			position: absolute;
			right: 20px;
			top: 0;
			line-height: 40px;
			cursor: pointer;
		}
		.pass{
			width: 150px;
		}
	}
	.sex{
		display: flex;
		margin-top: 15px;
		div{
			margin-right: 20px;
			img{
				margin-right: 10px;
			}
		}
	}
	.but{
		width: 321px;
		height: 45px;
		opacity: 1;
		background: #ff7c00;
		border-radius: 4px;
		text-align: center;
		margin-top: 20px;
		line-height: 45px;
		font-size: 18px;
		font-weight: 500;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
	}
}

</style>
